<template>
    <mf-box>
        <div slot="header">
            Quay Group
        </div>
        <div class="quay-group">
            <div class="container__row">
                <div class="container__item">
                    <span class="container__title">
                        Groups
                    </span>
                    <div class="group-list">
                        <div
                            v-for="(groupName, index) in Object.keys(groups)"
                            :key="`group-${index}`"
                            class="group-item"
                            :class="{ 'group-item_selected': groupName === selectedGroupName }"
                            @click.capture="handleClickGroup(groupName)"
                        >
                            {{ groupName }}
                            <mf-button
                                class="group-item__button"
                                size="x-small"
                                @click.capture="handleRemoveGroup(groupName)"
                            >
                                Remove
                            </mf-button>
                        </div>
                    </div>
                    <div class="buttons-container">
                        <mf-button size="x-small" @click="toggleModal">Add a new group</mf-button>
                    </div>
                </div>
                <div class="container__item">
                    <span class="container__title">
                        Grouped Quays
                    </span>
                    <div class="quays-list">
                        <template v-if="selectedGroupQuays && selectedGroupQuays.length">
                            <div v-for="(quay, index) in selectedGroupQuays" :key="`quay-${index}`" class="quay-item">
                                {{ quay }}
                                <mf-button class="quay-item__button" size="x-small" @click="handleRemoveQuay(quay)">
                                    Remove
                                </mf-button>
                            </div>
                        </template>
                    </div>
                    <div class="buttons-container">
                        <mf-button size="x-small" v-if="!isSelectShown" @click="toggleAddQuaySelect"
                            >Add quay</mf-button
                        >
                        <mf-select
                            v-else
                            class="quay-select"
                            placeholder="Search quay to add"
                            :options="quaysNames"
                            label="name"
                            @close="handleIsOpenedEvent"
                            @select="handleSelectQuay"
                        />
                    </div>
                </div>
            </div>
        </div>
        <create-group-modal :is-open="isCreateModalShown" @close="toggleModal" @handleNewGroupName="handleNewGroup" />
    </mf-box>
</template>

<script>
    import CreateGroupModal from '@/modals/CreateGroupModal';
    import { mapGetters } from 'vuex';
    import MfSelect from '@/components/default/MfSelect';

    export default {
        name: 'QuayGroup',
        data() {
            return {
                isCreateModalShown: false,
                isSelectShown: true,
                groups: {
                    'Traffic Area asda Adnjasdn Jasdnasd asdkaksdj kajdkasdj': [
                        'NOSVG:TUG_ZONE:TUG-B Byfjorden',
                        "NOSVG:TRAFFIC_AREA:Port of Stavanger's Traffic Area",
                        'NOSVG:BERTH:19E Margarinlinjen',
                        'NOSVG:ANCHORING_AREA:RZ Anker- og DP-posisjon',
                        'NOSVG:ANCHORING_AREA:Dysjalandsvaagen - Mosterøy/Askje',
                        'Test',
                        'Nosvg',
                        'Hahah',
                        'Test',
                    ],
                    Berth1: [],
                    Berth2: [],
                    Berth3: [],
                    Berth4: [],
                    Berth5: [],
                    Berth6: [],
                    Berth7: [],
                    Berth8: [],
                    'Tug Zonde': ['Test', 'Nosvg', 'Hahah'],
                },
                selectedGroupQuays: [],
                selectedGroupName: '',
            };
        },
        watch: {
            selectedGroupQuays: {
                handler(val) {
                    if (this.selectedGroupName) {
                        this.groups[this.selectedGroupName] = val;
                    }
                },
                immediate: true,
            },
        },
        mounted() {
            this.selectedGroupName = Object.keys(this.groups)[0];
            this.selectedGroupQuays = this.groups[this.selectedGroupName];
        },
        computed: {
            ...mapGetters(['quaysNames']),
        },
        methods: {
            handleClickGroup(group) {
                this.selectedGroupName = group;
                this.selectedGroupQuays = this.groups[this.selectedGroupName];
            },
            toggleModal() {
                this.isCreateModalShown = !this.isCreateModalShown;
            },
            handleIsOpenedEvent(value) {
                this.isSelectShown = value;
            },
            toggleAddQuaySelect() {
                this.isSelectShown = true;
            },
            handleNewGroup(groupName) {
                if (!Object.keys(this.groups).find(name => name === groupName)) {
                    this.selectedGroupName = groupName;
                    this.selectedGroupQuays = [];
                } else {
                    this.$toasted.error('Group with this name already exists!');
                }
                this.toggleModal();
            },
            handleSelectQuay(quay) {
                if (!this.groups[this.selectedGroupName].find(item => item === quay.name)) {
                    this.selectedGroupQuays.push(quay.name);
                } else {
                    this.$toasted.error('This quay already exists in the selected group');
                }
            },
            handleRemoveQuay(quay) {
                this.selectedGroupQuays = this.groups[this.selectedGroupName].filter(groupQuay => {
                    return groupQuay !== quay;
                });
            },
            handleRemoveGroup(groupName) {
                let groupsNames = Object.keys(this.groups);
                const currentGroupIndex = groupsNames.indexOf(groupName);
                const nextGroupIndex = currentGroupIndex === 0 ? 0 : currentGroupIndex - 1;
                delete this.groups[groupName];
                this.selectedGroupName = groupsNames[nextGroupIndex];
                this.selectedGroupQuays = this.groups[this.selectedGroupName];
            },
        },
        components: {
            MfSelect,
            CreateGroupModal,
        },
    };
</script>

<style lang="scss" scoped>
    @import '@/assets/sass/default/layout.scss';

    .quay-group {
        color: $white;
    }

    .container__title {
        font-size: $font-26;
        font-weight: 600;
        margin-bottom: $space-16;
    }

    .group-list {
        max-height: 315px;
        overflow-x: auto;
        padding-right: $space-16;
        margin-bottom: $space-16;
        @include scrollbar();
    }

    .container__item {
        width: 50%;
    }

    .group-item,
    .quay-item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;
        &:not(:last-child) {
            margin-bottom: $space-8;
        }
        font-size: $font-16;
        line-height: $font-24;
        color: $white;
    }

    .group-item {
        padding: $space-8 $space-16;
        font-weight: 600;
        border: 1px solid $marine-500;
        border-radius: 6px;
    }

    .group-item_selected {
        background-color: $marine-700;
    }

    .quays-list {
        max-height: 315px;
        overflow-y: auto;
        background-color: $marine-700;
        border-radius: 6px;
        margin-bottom: $space-16;
        @include scrollbar();
    }

    .quay-item {
        margin: 0 $space-16;
        padding: $space-8 0;
        border-bottom: 1px solid $marine-500;
    }

    .group-item__button,
    .quay-item__button {
        margin-left: $space-12;
    }

    .buttons-container {
        position: relative;
    }

    .quay-select {
        position: absolute;
    }
</style>
