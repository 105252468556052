<template>
    <mf-box :empty="!selectedQuay">
        <div slot="header">
            Quay Relations
        </div>
        <div class="quay-relations">
            <div class="container__row">
                <div class="container__item">
                    <template v-for="(quay, index) in relatedQuays">
                        <div class="quay-item" :key="`related-quay-${index}`">
                            <div class="quay-item__details">
                                <div class="relate-name">{{ quay.name }}:</div>
                                <div class="quay-block">
                                    <div class="source-item">
                                        <!--                                    {{ quay.sourceQuay }}-->
                                        {{ selectedQuay && selectedQuay.name }}
                                    </div>
                                    <div class="item-line"></div>
                                    <div class="destination_item">
                                        {{ quay.destinationQuay }}
                                    </div>
                                </div>
                            </div>
                            <mf-button class="quay-item__button" size="x-small" @click="handleRemoveRelation(quay)">
                                Remove
                            </mf-button>
                        </div>
                    </template>
                    <mf-button class="quay__add-button" size="x-small" @click="toggleCreateModal"
                        >Add a new relation</mf-button
                    >
                </div>
            </div>
        </div>
        <create-quay-relation-modal
            :source-quay="selectedQuay"
            :is-open="isCreateModalShown"
            @close="toggleCreateModal"
            @newQuayRelation="handleAddNewRelation"
        />
        <div slot="empty-text">
            Quay not selected
        </div>
    </mf-box>
</template>

<script>
    import { mapGetters } from 'vuex';
    import CreateQuayRelationModal from '@/modals/CreateQuayRelationModal';

    export default {
        name: 'QuayRelations',
        data() {
            return {
                isCreateModalShown: false,
                relatedQuays: [
                    {
                        sourceQuay: 'NOSVG:TUG_ZONE:TUG-B Byfjorden',
                        destinationQuay: 'NOSVG:TUG_ZONE:TUG-B',
                        name: 'Test movement',
                    },
                    {
                        sourceQuay: 'NOSVG:TUG_ZONE:TUG-B Byfjorden',
                        destinationQuay: "NOSVG:TRAFFIC_AREA:Port of Stavanger's Traffic Area",
                        name: 'Movement 1',
                    },
                    {
                        sourceQuay: 'NOSVG:TUG_ZONE:TUG-B Byfjorden',
                        destinationQuay: 'NOSVG:BERTH:19E Margarinlinjen',
                        name: 'Test movement 2',
                    },
                    {
                        sourceQuay: 'NOSVG:TUG_ZONE:TUG-B Byfjorden',
                        destinationQuay: 'NOSVG:ANCHORING_AREA:RZ Anker- og DP-posisjon',
                        name: 'movemenTTT 2',
                    },
                ],
            };
        },
        computed: {
            ...mapGetters(['selectedQuay']),
        },
        methods: {
            toggleCreateModal() {
                this.isCreateModalShown = !this.isCreateModalShown;
            },
            handleAddNewRelation(event) {
                //todo: check quay by URN
                if (!this.relatedQuays.find(relation => relation.destinationQuay === event.quay.name)) {
                    this.relatedQuays.push({
                        sourceQuay: this.selectedQuay.name,
                        name: event.relationName,
                        destinationQuay: event.quay.name,
                    });
                    this.toggleCreateModal();
                } else {
                    this.$toasted.error('Relation with this quay already exists!');
                }
            },
            handleRemoveRelation(quay) {
                this.relatedQuays = this.relatedQuays.filter(item => {
                    return item.destinationQuay !== quay.destinationQuay;
                });
            },
        },
        components: {
            CreateQuayRelationModal,
        },
    };
</script>

<style lang="scss" scoped>
    .quay-item {
        display: flex;
        justify-content: space-between;
        color: $white;
        font-size: $font-18;
        margin-bottom: $space-12;
        padding: $space-16;
        border: 1px solid $marine-500;
        border-radius: 6px;
    }
    .quay-item__details {
        display: flex;
        align-items: center;
    }
    .relate-name {
        margin-right: $space-8;
    }
    .quay-block {
        display: flex;
        align-items: flex-end;
        font-size: $font-16;
    }
    .item-line {
        width: 30px;
        border-bottom: 1px solid;
        margin: 0 10px;
        align-self: center;
    }
    .quay__add-button {
        margin-top: $space-24;
    }
    .destination_item {
        font-weight: 600;
    }
</style>
