<template>
    <div class="container">
        <div class="container__row">
            <div class="container__item">
                <quay-info />
            </div>
        </div>
        <div class="container__row">
            <div class="container__item">
                <quay-group />
            </div>
        </div>
        <div class="container__row">
            <div class="container__item">
                <quay-relations />
            </div>
        </div>
        <div class="container__row">
            <div class="container__item container__item_half">
                <vessels-at-quay />
            </div>
            <div class="container__item container__item_half">
                <quay-location />
            </div>
        </div>
        <div class="container__row">
            <div class="container__item">
                <quay-timeline />
            </div>
        </div>
    </div>
</template>

<script>
    import QuayLocation from '@/components/quay-components/QuayLocation';
    import VesselsAtQuay from '@/components/vessel-components/VesselsAtQuay';
    import QuayTimeline from '@/components/quay-components/QuayTimeline';
    import QuayInfo from '@/components/quay-components/QuayInfo';
    import QuayGroup from '@/components/quay-components/QuayGroup';
    import { mapGetters } from 'vuex';
    import { getQuayFromStore } from '@/helpers/getQuayFromState.helper';
    import QuayRelations from '@/components/quay-components/QuayRelations';

    export default {
        name: 'QuayPreview',
        components: {
            QuayLocation,
            VesselsAtQuay,
            QuayTimeline,
            QuayInfo,
            QuayGroup,
            QuayRelations,
        },
        watch: {
            '$route.params.id': {
                async handler(val) {
                    if (this.selectedQuayId !== val && val) {
                        const quay = await getQuayFromStore(val);
                        this.$store.commit('setSelectedQuay', quay);
                    } else if (!val) {
                        this.$store.commit('setSidebarValue', true);
                    }
                },
                immediate: true,
            },
        },
        computed: {
            ...mapGetters(['selectedQuayId']),
        },
    };
</script>

<style lang="scss" scoped>
    @import '@/assets/sass/default/layout.scss';
</style>
